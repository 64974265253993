import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset from '../../assets/tick.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/banner.jpg';
import { useDispatch, useSelector } from 'react-redux';
import './Home.scss';

const arrayText = [
  '097***4 đã rút 35.000.000 đ',
  '033***8 đã rút 30.000.000 đ',
  '087***3 đã rút 45.000.000 đ',
  '033***1 đã rút 100.000.000 đ',
  '035***5 đã được duyệt hồ sơ',
  '033***5 đã rút 80.000.000 đ',
];

export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1) {
        setCurrentIndex((prev) => prev + 1);
      } else {
        setCurrentIndex(0);
      }
    }, 1800);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearInterval(blinkInterval);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 1 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ textAlign: 'left', marginRight: 'auto' }}>
          <Typography.Text className="text-head">Xin chào, </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>
        <div style={{ textAlign: 'right', marginRight: '0px' }}> {/* Giảm khoảng cách ở đây */}
          <Typography.Text className="text-head" style={{ textAlign: 'right' }}>
            Mcredit <br /> Kính chào quý khách
          </Typography.Text>
        </div>
        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
          style={{ marginLeft: '10px', cursor: 'pointer' }} // Giảm khoảng cách giữa chuông và tiêu đề
        >
          <svg viewBox="64 64 896 896" focusable="false" data-icon="bell" width="1.5em" height="1.5em" fill="currentColor" aria-hidden="true">
            <path d="M816 768h-24V428c0-141.1-104.3-257.8-240-277.2V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.8C336.3 170.2 232 286.9 232 428v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48z"></path>
          </svg>
        </motion.div>
      </div>

      <div className="noti-text-container">
        <Typography.Text className="noti-text">
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>
    
      <Carousel autoplay autoplaySpeed={3000} className="carousel-container">
        <div>
          <div className="carousel slider6">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider7">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider8">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider9">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider10">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider14">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>
      <div
  style={{
    position: 'relative', // Đặt vị trí cha
    height: '10vh', // Đặt chiều cao là toàn bộ viewport height
  }}
>
  <Button
    className="choose-btn"
    onClick={() => {
      history.push('/vay');
    }}
    style={{
      position: 'absolute',
      top: '0vh', // Điều chỉnh vị trí nút từ trên xuống
      left: '50%',
      transform: 'translateX(-50%)', // Căn giữa theo chiều ngang
    }}
  >
    <Typography.Text
      style={{
        fontWeight: 'bold',
        fontSize: 25,
        color: blink ? 'yellow' : 'white',
        transition: 'color 0.5s',
      }}
    >
      Đăng ký khoản vay
    </Typography.Text>
  </Button>
</div>

      <div style={{ padding: 20 }}>
        <Tab
          title={'Thủ tục vay nhanh chóng, đơn giản'}
          icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
        />
        <Tab
          title={'Hạn mức vay lên đến 500tr VNĐ'}
          icon={<DashboardFilled style={{ fontSize: 20, color: '#008f50' }} />}
        />
        <Tab
          title={'Nhận tiền chỉ sau 30 phút làm hồ sơ'}
          icon={<SlidersFilled style={{ fontSize: 20, color: '#141ed2' }} />}
        />
      </div>
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider4">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider13">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <div className="carousel slider5">
            <div className="sl-text-container"></div>
          </div>
        </div>
      
      </Carousel>
      <div
        style={{
          paddingTop: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image src={tickAsset} preview={false} style={{ maxWidth: 200 }} />
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
          }}
        >
          &reg;Bản quyền thuộc về Công ty Tài chính TNHH MB Shinsei (Mcredit).
          <br /> Tất cả các quyền được bảo hộ.
          <br /> Copyright © 2023 Mcredit
        </Typography.Text>
      </div>
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid #141ed2',
      }}
      onClick={onClick}
      style={{
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 15px',
        border: '1px solid #141ed2',
        borderRadius: 5,
      }}
    >
      <Typography.Text
        style={{ flex: 1, fontSize: 14, color: '#141ed2', fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
    </motion.div>
  );
}
