// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  display: flex !important;
  justify-items: center !important;
  text-align: center !important;
  padding: 25px 20px;
}

.body-title {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.zalo {
  background: 10px 10px 10 rgba(0, 0, 0, 0.2) !important;
  width: 60% !important;
}

.footer {
  display: flex;
  justify-content: center;
}

.back-btn {
  border: 1px #c4c4c4 !important;
  background: #141ed2 !important;
  border-radius: 30px !important;
  height: 50px !important;
  color: white !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/CSKH/cskh.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,gCAAA;EACA,6BAAA;EACA,kBAAA;AACJ;;AACA;EACI,0BAAA;EACA,2BAAA;AAEJ;;AAGA;EACI,sDAAA;EACA,qBAAA;AAAJ;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ;;AACA;EAEI,8BAAA;EACA,8BAAA;EACA,8BAAA;EACA,uBAAA;EACA,uBAAA;EACA,0BAAA;EACA,2BAAA;AACJ","sourcesContent":[".body{\n    display: flex !important;\n    justify-items: center !important;\n    text-align: center !important;\n    padding: 25px 20px ;\n}\n.body-title{\n    font-size: 25px !important;\n    font-weight: 400 !important;\n}\n.zalo-container{\n    \n}\n.zalo{\n    background: 10px 10px 10 rgb(0 0 0 /0.2) !important;\n    width: 60% !important;\n}\n.footer{\n    display: flex;\n    justify-content: center;\n}\n.back-btn {\n    \n    border: 1px #c4c4c4 !important;\n    background:  #141ed2 !important;\n    border-radius: 30px !important;\n    height: 50px !important;\n    color: white !important;\n    font-size: 20px !important;\n    font-weight: 500 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
